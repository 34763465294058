export const lang = {
    //公共
    document_title_figcheck:'Figcheck | Check for duplicate images',
    figcheck_confirm:'Confirm',
    figcheck_confirm_pay:'Confirm',
    figcheck_key_point: "FigCheck is free!",
    figcheck_no_data:'no data',
    figcheck_timeout_dialog_title:'Tips',
    figcheck_timeout_dialog_conetent_html:'Timeout! The picture size is too large or there are too many pictures uploaded. Contact admin@figcheck.com for help.',
    figcheck_org_user_gtlimit_dialog_conetent_html:'The number of your queries has exceeded the maximum limit for institutional users. Contact admin@figcheck.com for help.',
    //首页
    figcheck_global_tip_title:'Tips',
    figcheck_global_tip_confirm_known:'Noticed',
    figcheck_global_tip_confirm_ok:'ok',
    // figcheck_home_checkout: 'Unregistered users can use this function up to {0} times. Please register with your email address to keep using FigCheck {1} times per day.',
    figcheck_home_checkout: 'Please register with your email address to keep using FigCheck {1} times per day.',
    //顶部
    figcheck_header_lang: "中文",
    figcheck_header_login: "Login",
    figcheck_header_login_wechat: "Login by WeChat",
    figcheck_header_login_prompt: "Login first for better function use",
    figcheck_header_quit: "Click to logout",
    figcheck_header_ret_home: "Click to back home",
    //简介及统计
    figcheck_intro:'In recent years, research integrity has received much-needed attention. Image plagiarism is becoming one of the main issues that need to be tackled. FigCheck uses artificial intelligence algorithms to automate segmentation, recognition, annotation and report generation. The feasibility of this tool is supported by previous testing involving more than 1000 pictures from published articles, with an accuracy rate of over 95%.',
    figcheck_stat_country_unit:'',
    figcheck_stat_country_prompt:'Countries and regions',
    figcheck_stat_user_unit:'',
    figcheck_stat_user_prompt:'Users',
    figcheck_stat_pic_process_unit:'',
    figcheck_stat_pic_process_prompt:'Image processed',
    figcheck_stat_pic_problem_unit:'',
    figcheck_stat_pic_problem_prompt:'Issues',
    //上传部分
    figcheck_upload_prompt_prefix:'Drag files here or ',
    figcheck_upload_prompt_click:'Click',
    figcheck_upload_prompt_postfix:' to upload',
    figcheck_upload_small_prompt:'File Formats: jpg, png, tiff, pdf, docx',
    figcheck_upload_title:'Uploaded:',
    figcheck_upload_start_check:'Processing',
    figcheck_upload_start_checking:'Processing...',
    figcheck_upload_reupload:'Re-upload',
    figcheck_upload_split_wait:'At present, there are a large number of inquiries. You are waiting in line. Please wait patiently.',
    figcheck_upload_time_predict:'The check will be completed in around {0} seconds',
    figcheck_upload_limit_pdf:'PDF format support for article submission only with a maximum of one file at a time. Multiple figures must be uploaded in other formats (jpg, png, Tif).',
    figcheck_upload_limit_pdf_page:' Your PDF file has exceeded the limit ({0} pages), please reduce it as appropriate, thank you.',
    figcheck_upload_error_pdf:'The PDF file format is incorrect. Please upload another one. Thank you.',
    figcheck_upload_limit_pdf_org:'The maximum number of documents in PDF format for institutional users is 50, thank you.',
    figcheck_upload_limit_size:'Please do not exceed {0}MB per picture or pdf document. Thank you.',
    figcheck_upload_limit_size_pdf:'Please do not exceed {0}MB per pdf document. Thank you.',
    figcheck_upload_limit_size_image:'Please do not exceed {0}MB per picture. Thank you.',
    figcheck_upload_limit_size_total:'Please do not exceed {0}MB total picture or pdf document. Thank you.',
    figcheck_upload_limit_format:'Incorrect picture format (jpg, png, tiff), docx , PDF file.',
    figcheck_upload_limit_num:'Too many pictures uploaded. Please re-upload with fewer pictures.',
    figcheck_upload_limit_gt_count:'There are too many detected pictures, or too high-definition, please try to delete the pictures or reduce the picture pixels and try again. Decreasing the resolution will not affect the detection sensitivity.',
    figcheck_upload_limit_not_gt_10:'Please do not exceed 10 files per search. Thank you.',
    figcheck_upload_limit_gt_3_100s:'Your account has been checked for more than {1}s for {0} times. If you want to continue to use it, please contact us, thank you.',
    //结果部分
    figcheck_result_source_title:'Similar sources:',
    figcheck_result_source_title_no_result:'No matches',
    figcheck_result_ps_source_title:'Original image:',
    figcheck_result_ps_source_title_no_result:'No pictures with traces of ps were detected.',
    figcheck_result_detail_title:'Details:',
    figcheck_result_ps_detail_title:'Ps trace prediction: ',
    figcheck_result_detail_option_show_line:'Markers',
    figcheck_result_detail_option_show_line_intro:'Red lines connect similar characteristics between two images to indicate their correlation, such as copy, rotation, inversion, etc.',
    figcheck_result_detail_option_remove_label:'Clear vision',
    figcheck_result_detail_option_remove_label_intro:'Annotations around the picture (such as lettering, scales etc.) will not be included, removing the false positive interference.',
    figcheck_result_detail_option_ps:'PS traces',
    figcheck_result_detail_option_ps_intro:'The WB image in the file will be individually identified, and the type includes two image manipulations of "copy, removal". After an internal test of 1,000 samples, the true positive rate of the results is about 92.3%. ',
    figcheck_result_single_ps_pred_prefix: 'Suspected ',
    figcheck_result_single_ps_pred_text_add: 'replication',
    figcheck_result_single_ps_pred_text_remove: 'removal',
    figcheck_result_ps_no_wb:'There is no wb diagram in the result, so you cannot use ps query. ',
    figcheck_result_detail_prompt:'Tip: Although artificial intelligence algorithm greatly improves the recognition efficiency for duplicate pictures, the repeated part marked in red lines may not be always meaningful, such as the icons and the fluorescence images (merge). Generally, the more convergence the red lines, the greater the possibility of repetition. Please consider the actual situation. ',
    figcheck_result_ps_detail_prompt:'The PS trace detection function has been tested on 1000 internal samples, and the true positive rate of the results is about 92.3%, that is, 92.3% of the highlighted areas above may be the type of image manipulation shown on the right. But there are still 7.7% false positives, please judge according to the actual situation. ',
    figcheck_result_down_pdf:'Download Report',
    figcheck_result_downing_pdf:'Preparing...',
    //pdf报告
    figcheck_result_pdf_filename:'FigCheck Originality Report',
    figcheck_result_pdf_processing:'Processing. Try again later',

    figcheck_case_title:'Examples of image plagiarism',
    figcheck_links_title:'Links',
    figcheck_links_link_3:'国家卫健委',
    figcheck_links_link_4:'国务院',
    figcheck_links_link_5:'中华医学会',
    figcheck_links_link_6:'国自然基金委',
    //新增 9月18号

    //承诺书
    figcheck_book_link:'Academic Integrity Initiative',
    figcheck_book_title:'Academic Integrity Initiative',
    figcheck_book_content:'<p>\n' +
        '              Researchers in all fields of academia: \n' +
        '            </p>\n' +
        '            <p style="text-indent:2em;">\n' +
        '              Honesty is the virtue appreciated worldwide. Academic integrity maintenance has long been a task in academia, and “Picture Repetition” is growing prominent that requires more attention. Being the scientific research workers, we are expected to properly understand the nature of academic integrity and the underlying connotations. In the meantime, we should start from our own and conform to relevant laws and regulations. We need to keep making a stand against plagiarism or other misconducts, respect other’s academic achievements, and maintain academic integrity. \n' +
        '            </p>\n' +
        '            <p style="text-indent:2em;">\n' +
        '              It should be a good idea if we are persistent in academic integrity. Let us promise to credit “Academic Integrity” and be an excellent researcher! \n' +
        '            </p>\n' +
        '            <p class="book-footer">\n' +
        '              FigCheck <br/>\n' +
        '              September 6, 2021\n' +
        '            </p>',
    figcheck_book_cur_support:'Supported by {0} people',
    figcheck_book_btn_support:'Support',
    figcheck_book_btn_has_support:'Supported',
    figcheck_book_msg_support_succ:'Supported',
    figcheck_book_msg_support_fail:'Support failed',
    //登录注册
    //公共
    figcheck_loginbox_input_email:'Email address',
    figcheck_loginbox_input_pass:'Password',
    figcheck_loginbox_link_login_wechat:'微信登录',
    figcheck_loginbox_link_reg:'Sign up',
    figcheck_loginbox_link_find_pass:'Forgot password?',
    figcheck_loginbox_link_login:'Log in',
    //登录
    figcheck_loginbox_login_title:'Log in',
    figcheck_loginbox_login:'Log in',
    //注册
    figcheck_loginbox_reg_title:'Sign up by Email',
    figcheck_loginbox_send_code:'Get code',
    figcheck_loginbox_input_code:'Security code',
    figcheck_loginbox_reg:'Sign up',
    //找回密码
    figcheck_loginbox_reset_title:'Reset password',
    figcheck_loginbox_input_new_pass:'New password',
    //错误提示
    figcheck_loginbox_err_email:'Invalid Email address! Please try again.',
    figcheck_loginbox_err_pass:'Invalid password! Your password must contain at least 6 characters.',
    figcheck_loginbox_err_code:'Invalid code. The security code must contain 4 characters.',
    //消息提示
    figcheck_loginbox_msg_login_succ:'Login successful',
    figcheck_loginbox_msg_login_fail:'Login error. Try again.',
    figcheck_loginbox_msg_send_limit:'Too many operations. Try again later.',
    figcheck_loginbox_msg_reg_succ:'Successful registration. You may log in using your email address and password.',
    figcheck_loginbox_msg_reset_succ:'Password reset successful.',
    figcheck_loginbox_msg_reset_succ_login_fail:'Password reset successfully.  Auto-login failed, please try again manually.',
    figcheck_loginbox_msg_reset_fail:'Password reset failed.',
    figcheck_loginbox_msg_send_email_fail:'Sending failed.',
    figcheck_loginbox_msg_send_email_succ:'Sent.',
    figcheck_loginbox_msg_code_err:'Invalid security code. Four failed attempts, one attempt left.',
    figcheck_loginbox_msg_code_err_no_num:'Incorrect security code.',
    figcheck_loginbox_msg_login_gt_limit:'You have exceeded the maximum number of attempts for this action. Try again later.',
    figcheck_loginbox_msg_login_user_in_black_list:'Your account has been locked.',
    figcheck_loginbox_msg_login_ip_in_black_list:'Your IP has been locked.',
    figcheck_loginbox_msg_user_pass_err:'Username/password is incorrect. Three more attempts left.',
    figcheck_loginbox_msg_user_not_exist:'There is no user registered with that Email address. Check your Email address and try again later.',
    figcheck_loginbox_msg_user_has_exist:'The account already exists. Please log in.',
    figcheck_loginbox_msg_reg_fail:'Registration failed. Try again.',
    figcheck_loginbox_msg_logout_succ:'Logout successful',
    //邮箱验证码
    figcheck_email_titile:'Security code sent by FigCheck',
    figcheck_email_content:'Your security code is {1}. The code will expire in 5 mins, please enter the code quickly for successful registration',
    //头部菜单
    figcheck_menu_self_check:'Academic self-examination',
    figcheck_menu_readme:'Instructions for use',
    figcheck_menu_pub_sub:'Pubpeer subscription',
    figcheck_menu_peer_review:'Peer review',
    figcheck_menu_api_access:'Institutional API access',

    //使用说明
    figcheck_readme_title:'Figcheck instructions for use',
    figcheck_readme_sub_module_1_title:'Introduction',
    figcheck_readme_sub_module_1_intro_1:'In recent years, research integrity has received more and more attention from the academic community, among which the issue of "image reuse" has received unprecedented attention thanks to the exposure from Pubpeer and other platforms.\n' +
        '                  Figcheck is a project of Tongji University\'s ”venture valley“ initiative. This software is based on an artificial intelligence framework and neural network algorithms that can automate the segmentation, identify duplication, annotate and print a report of pre-submitted images or pictures in PDF documents with one click. Figcheck is dedicated to providing an easy and free image checking tool for researchers, research institutions or journal publishers, etc.',
    figcheck_readme_sub_module_2_title:'Instructions for use',
    figcheck_readme_sub_module_2_intro_1:'Individual users need to log in by email and have one free checking permission per day. Institutional accounts are available for organizations (such as publishers or universities) that need unlimited access and batch checking privileges. Figcheck also provides API access services, and the application process for institutional account privileges is detailed at <a\n' +
        '                  href="https://www.figcheck.com/org_api" target="_blank" class="link">https://www.figcheck.com/org_api</a>',
    figcheck_readme_sub_module_2_intro_2:'Login to the Figcheck website (<a href="https://www.figcheck.com/" target="_blank" class="link">https://www.figcheck.com/</a>), "click" or drag and drop to upload files in jpg, png, tif/tiff, pdf, zip formats. File size must be no larger than 30M.',
    figcheck_readme_sub_module_2_intro_3:'Figcheck only detects potential image duplication within the uploaded files or compressed files. Result processing should take around 10-20 seconds to complete (based on 5 large images). The processing might take longer, subject to the number of online requests at the time.',
    figcheck_readme_sub_module_3_title:'Result interpretation',
    figcheck_readme_sub_module_3_label_1:'Result interface',
    figcheck_readme_sub_module_3_intro_1:'As shown above, the middle area of the page displays all the images that have been potentially reused. (the red frame indicates the specific area). In the example above, there are a total of 17 potentially similar images. The right side of the page is where the detailed comparisons between suspected image pairs are displayed. The red lines mark the similar feature points between the two images and how that image was transformed (cut, flip, rotate, scale and a combination). For instance:',
    figcheck_readme_sub_module_3_label_2_1_1:'Example 1: ',
    figcheck_readme_sub_module_3_label_2_1_2:'Cropping',
    figcheck_readme_sub_module_3_label_2_2_1:'Example 2: ',
    figcheck_readme_sub_module_3_label_2_2_2:'Flipping',
    figcheck_readme_sub_module_3_label_2_3_1:'Example 3: ',
    figcheck_readme_sub_module_3_label_2_3_2:'Zooming',
    figcheck_readme_sub_module_3_intro_2:'Positive results in Figcheck do not necessarily mean "image manipulation". The user must determine this based on actual circumstances, such as two different fluorescence color-labeled cell fluorescence plots and their corresponding merge plots, actual zooms of microscope images, and picture annotations, which are common and normal duplications found in academic papers.',
    figcheck_readme_sub_module_3_intro_3:'Theoretically, any apparent similarities will be flagged by Figcheck, so there will be sporadic false-positive results, but these can be easily distinguished. For instance:',
    figcheck_readme_sub_module_3_label_3_1_1:'False positives due to blank spaces',
    figcheck_readme_sub_module_3_label_3_1_2:'Peripheral insignificant duplicates',
    figcheck_readme_sub_module_3_intro_4:'We have added some functional too to aid users in better interpreting the final results, namely "Show lines", "Remove annotation" and "PS traces". The explanation of each button can be viewed by clicking on the ‘?’ next to each button. As shown in the figure below.',
    figcheck_readme_sub_module_3_prompt_1:'Show lines：',
    figcheck_readme_sub_module_3_text_1:'Red lines connect similar features between two images. This can be changed to show the frame only or clear view.',
    figcheck_readme_sub_module_3_prompt_2:'Remove annotation：',
    figcheck_readme_sub_module_3_text_2:'The marked areas of the image will not be included in the image check to minimize the number of false positives. ',
    figcheck_readme_sub_module_3_prompt_3:'PS traces：',
    figcheck_readme_sub_module_3_text_3:'Detection of photoshop remnants in WB images of uploaded files (not available yet)',
    figcheck_readme_sub_module_4_video_1_desc:'Video for individual users',
    figcheck_readme_footer_name:'Figcheck team',
    figcheck_readme_footer_contact:'Contact us：admin@figcheck.com',

    //pubpeer订阅
    figcheck_sub_tip:'Login first for subscription function.',
    figcheck_sub_back_home:'Back homepage',
    figcheck_sub_header_tip:'Pubpeer is currently a well-known platform to expose academic misconduct. It aims to provide real-time tracking of a target article and informs the author by Email or SMS.',
    figcheck_sub_block_add:'Article to be tracked',
    figcheck_sub_input_doi:'Enter DOI',
    figcheck_sub_title:'Title',
    figcheck_sub_confirm:'Confirm',
    figcheck_sub_my:'Articles tracked',
    figcheck_sub_delete:'Delete',
    figcheck_sub_no_tip:'No article tracked yet. Maximum 20 articles.',
    figcheck_sub_term_gt_limit:'Your current subscription function does not allow for tracking more articles. Please update your subscription setting by Email: admin@figcheck.com',
    figcheck_sub_alarm_mode:'Alarm mode',
    figcheck_sub_email:'Email',
    figcheck_sub_input_email:'Enter your Email address',
    figcheck_sub_sms:'SMS',
    figcheck_sub_input_phone:'Enter your phone number',
    figcheck_sub_start:'Start to track',
    figcheck_sub_stop:'Close the track',
    figcheck_sub_tracking:'Tracking… The result will be informed by the Email/SMS you provided.',
    figcheck_sub_untrack:'Pubpeer tracking has not been connected',
    figcheck_sub_alarm_his:'Alarm history',
    figcheck_sub_alarm_his_no:'No.',
    figcheck_sub_alarm_his_title:'Title',
    figcheck_sub_alarm_his_doi:'DOI',
    figcheck_sub_alarm_his_send_time:'Created at',
    figcheck_sub_alarm_his_action:'Action',
    figcheck_sub_alarm_his_open:'Open',
    figcheck_sub_msg_hint:'Hint',
    figcheck_sub_msg_delete_article:'Do you want to remove the article?',
    figcheck_sub_msg_confirm:'Confirm',
    figcheck_sub_msg_cancel:'Cancel',
    figcheck_sub_msg_delete_msg:'Do you want to delete the message?',
    figcheck_sub_msg_no_input:'Please enter your Email or phone number.',
    figcheck_sub_msg_enter:'Enter',
    figcheck_sub_msg_reenter:'Reenter',
    figcheck_sub_msg_err_email:'Invalid Email address. Try again later.',
    figcheck_sub_msg_err_phone:'Incorrect phone number. Try again later.',
    figcheck_sub_msg_err_doi:'DOI is required',
    figcheck_sub_msg_err_article_exist:'This article already exists.',
    figcheck_sub_msg_err_out_limit:'Out of limit',
    figcheck_sub_msg_err_unavai_article_id:'Unavailable article ID',
    figcheck_sub_msg_err_no_id:'No ID',
    figcheck_sub_msg_err_alarm_illegel_id:'Alarm history Illegal ID',
    figcheck_sub_msg_err_no_his_id:'No history of this ID',
    figcheck_sub_msg_login_first:'Login first',
    figcheck_sub_msg_added:'Added',
    figcheck_sub_msg_removed:'Removed',
    figcheck_sub_msg_delete_fail:'Delete failed',
    figcheck_sub_msg_opened:'Opened',
    figcheck_sub_msg_closed:'Closed',
    figcheck_sub_msg_open_fail:'Open failed',
    figcheck_sub_msg_close_fail:'Close failed',
    //机构api
    figcheck_org_api_box_1_title:'FigCheck for Institutions',
    figcheck_org_api_box_1_text:'Academic misconduct such as“Image plagiarism” is a common enemy in academia. Academic communities, research groups, colleges and universities, journals, etc. can obtain higher privileges in the following 3 ways:',
    figcheck_org_api_box_1_dot_1:'Unrestricted personal account (unrestricted daily check)',
    figcheck_org_api_box_1_dot_2:'Batch check permissions ',
    figcheck_org_api_box_1_dot_3:'Access function through API ',
    figcheck_org_api_box_1_dot_video:'Video demo',
    figcheck_org_api_box_2_title:'Applications:',
    figcheck_org_api_box_2_dot_1:'The publishing house reviews the manuscripts in batches',
    figcheck_org_api_box_2_dot_2:'Review of published articles by academic or medical institutions',
    figcheck_org_api_box_2_dot_3:'Self inspection by academic institutions or individuals before submission',
    figcheck_org_api_box_3_title:'Users: ',
    figcheck_org_api_box_3_dot_1:'Publishing Group',
    figcheck_org_api_box_3_dot_2:'Universities and Colleges',
    figcheck_org_api_box_3_dot_3:'Research institutions',
    figcheck_org_api_box_3_dot_4:'Research Group',
    // figcheck_org_api_apply_step:'Institutional accreditation and service',
    // figcheck_org_api_apply_step_1_title:'Certification Materials',
    // figcheck_org_api_apply_step_1_text:'Supportive materials and contacts',
    // figcheck_org_api_apply_step_2_title:'Sign agreements',
    // figcheck_org_api_apply_step_2_text:'Including agreement for FigCheck access, confidentiality agreement, etc.',
    // figcheck_org_api_apply_step_3_title:'Bind to Scene',
    // figcheck_org_api_apply_step_3_text:'Support other scenes in need by a Task Force to maintain normal operation and resolve problems.',
    figcheck_org_api_apply_step:'Application Process',
    figcheck_org_api_apply_type_1_title:'Institutional Account Application',
    figcheck_org_api_apply_type_1_text:'Suitable for institutions with no websites and submission systems, such as research institutes or research groups, to obtain permissions to check for duplicate images in batches.',
    figcheck_org_api_apply_type_1_step_1_title:'Contact Us',
    figcheck_org_api_apply_type_1_step_1_text:'Contact us through our website',
    figcheck_org_api_apply_type_1_step_2_title:'Certification Materials',
    figcheck_org_api_apply_type_1_step_2_text:'Supporting materials and contacts',
    figcheck_org_api_apply_type_1_step_3_title:'Sign agreement',
    figcheck_org_api_apply_type_1_step_3_text:'confidentiality agreements.',
    figcheck_org_api_apply_type_1_step_4_title:'Account assignment',
    figcheck_org_api_apply_type_1_step_4_text:'Grant administrator permissions',
    figcheck_org_api_apply_type_2_title:'API Access',
    figcheck_org_api_apply_type_2_text:'Pertains to institutions with their own website and submission system, such as publishers, to connect to the FigCheck function through API access.',
    figcheck_org_api_apply_type_2_step_1_title:'Contact Us',
    figcheck_org_api_apply_type_2_step_1_text:'Contact us through our Website',
    figcheck_org_api_apply_type_2_step_2_title:'Certification Materials',
    figcheck_org_api_apply_type_2_step_2_text:'Supporting materials and contacts',
    figcheck_org_api_apply_type_2_step_3_title:'Sign agreement',
    figcheck_org_api_apply_type_2_step_3_text:'Including software service contract and confidentiality agreements, etc.',
    figcheck_org_api_apply_type_2_step_4_title:'Binding scenarios',
    figcheck_org_api_apply_type_2_step_4_text:'Technical assistance for an establishment based on institutional scenarios',
    figcheck_org_api_contact:'Contact us',
    figcheck_org_api_email:'Email address:',
    figcheck_org_api_phone:'Phone number:',
    //同行评议
    figcheck_peer_review_add_report:'Want to report',
    figcheck_peer_review_comment:'Comment',
    figcheck_peer_review_hide:'Hide',
    figcheck_peer_review_more:'More',
    figcheck_peer_review_floor:'Floor',
    figcheck_peer_review_input_hint:'Write down your comments…',
    figcheck_peer_review_preview:'Preview',
    figcheck_peer_review_reply:'Reply',
    figcheck_peer_review_load_more:'Loading more…',
    figcheck_peer_review_no_more:'No more',
    figcheck_peer_review_article_info:'Article information',
    figcheck_peer_review_title:'Title',
    figcheck_peer_review_authors:'Authors',
    figcheck_peer_review_journal:'Journal',
    figcheck_peer_review_doi:'DOI',
    figcheck_peer_review_tip:'Full information will be displayed automatically with the input of article DOI',
    figcheck_peer_review_year:'Year',
    figcheck_peer_review_choose_year:'Choose year',
    figcheck_peer_review_contents:'Contents',
    figcheck_peer_review_loading:'Loading…',
    figcheck_peer_review_reply_floor:'Reply to the {0}st Floor:',
    figcheck_peer_review_reply_floor_reg: 'Reply to the (\\d+)st Floor:',
    figcheck_peer_review_commented:'Commented',
    figcheck_peer_review_released:'Released',
    figcheck_peer_review_login_first:'Login first',
    figcheck_peer_review_content_is_null:'Revelations are required',
    figcheck_peer_review_doi_is_null:'DOI is required',
    figcheck_peer_review_add_fail:'Release failed. Try again later.',
    figcheck_peer_review_comments_is_null:'Comments are required',
    figcheck_peer_review_no_right:'No rights',


    // 10-4
    // 期刊直通车
    //公共
    document_title_subway:'期刊直通车',

    //头部
    subway_menu_coop_journal: '合作期刊',
    subway_menu_editor_team: '编辑团队',
    subway_menu_privacy_policy: '隐私政策',
    subway_user_login: '账号登录',
    subway_lang: '语言',
    subway_lang_zh: '中文',
    subway_lang_en: 'english',
    subway_search_hint: 'Title/keywords 搜索期刊',
}
