import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
// let originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
//
// originalPush = VueRouter.replace;
// VueRouter.replace = function replace(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
  mode: 'history',  //去掉url中的#
  routes: [
    // {
    //   path: '/',
    //   name: 'AutoDock',
    //   redirect:'/index',
    //   component: () => import('@views/autodock/Index.vue')
    // },
    // {
    //   path: '/v2',
    //   name: 'MedReading',
    //   meta: {
    //     hideHeader: false
    //   },
    //   component: () => import('@views/autodock/Index.vue')
    // },

    {
      path: '/',
      name: 'autodock',
      redirect:'/DockCompound',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/DockCompound.vue')
    },
    {
      path: '/DockCompound',
      name: 'DockCompound',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/DockCompound.vue')
    },
    {
      path: '/DockPeptide',
      name: 'DockPeptide',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/DockPeptide.vue')
    },
    {
      path: '/DockProtein',
      name: 'DockProtein',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/DockProtein.vue')
    },
    {
      path: '/Pay',
      name: 'Pay',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/Pay.vue')
    },
    {
      path: '/Individuation',
      name: 'Individuation',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/Individuation.vue')
    },
    {
      path: '/MD',
      name: 'MD',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/MD.vue')
    },
    {
      path: '/VS',
      name: 'VS',
      meta: {
        hideHeader: false
      },
      component: () => import('@views/autodock/VS.vue')
    },
  ],
  // routes
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
