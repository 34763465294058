export const lang = {
    //公共
    document_title_figcheck:'Figcheck | 图片查重',
    figcheck_confirm:'确定',
    figcheck_confirm_pay:'捐赠',
    figcheck_key_point: "维护科研诚信，杜绝学术不端",
    figcheck_no_data:'暂无数据',
    figcheck_timeout_dialog_title:'温馨提示',
    figcheck_timeout_dialog_conetent_html:'系统超时！通常由于图片存储过大或数量过多引起，可尝试减少。或联系工作人员解决：',
    figcheck_org_user_gtlimit_dialog_conetent_html:'你的查询次数已超过机构用户的最大限制。请联系工作人员解决：',
    //首页
    figcheck_global_tip_title:'温馨提示',
    figcheck_global_tip_confirm_known:'知道了',
    figcheck_global_tip_confirm_ok:'好的',
    figcheck_home_checkout: '请先扫码登录微信，每天可查重{1}次，谢谢。',
    //顶部
    figcheck_header_lang: "English",
    // figcheck_header_login: "邮箱登录",
    figcheck_header_login: "微信登录",
    figcheck_header_login_wechat: "微信登录",
    figcheck_header_login_prompt: "每日{0}次查重机会,请合理使用",
    figcheck_header_quit: "点击注销",
    figcheck_header_ret_home: "点击返回FigCheck首页",
    //简介及统计
    figcheck_intro:'近年来，科研诚信越来越受到学界重视，其中“图片重复使用”问题值得关注。FigCheck运用人工智能算法，可对投稿图片或PDF文献中的图片进行自动化分割、重复性识别、标注、报告打印。经过前期1000+次测试结果表明，Figcheck可以发现95%以上的重复图片。',
    figcheck_stat_country_unit:'国家',
    figcheck_stat_country_prompt:'地区',
    figcheck_stat_user_unit:'人',
    figcheck_stat_user_prompt:'用户',
    figcheck_stat_pic_process_unit:'次',
    figcheck_stat_pic_process_prompt:'图片处理',
    figcheck_stat_pic_problem_unit:'张',
    figcheck_stat_pic_problem_prompt:'潜在问题图片',
    //上传部分
    figcheck_upload_prompt_prefix:'将Figure拖动到此处, 或者',
    figcheck_upload_prompt_click:'点击',
    figcheck_upload_prompt_postfix:'上传',
    figcheck_upload_small_prompt:'支持格式: jpg, png, tif, pdf, docx',
    figcheck_upload_title:'已上传的图片: ',
    figcheck_upload_start_check:'开始检查',
    figcheck_upload_start_checking:'检查中...',
    figcheck_upload_reupload:'重新上传',
    figcheck_upload_time_predict:'大约 {0} 秒后完成检查',
    figcheck_upload_split_wait:'当前查询人数较多,正在排队中,请耐心等候...',
    figcheck_upload_limit_pdf:'PDF格式仅支持文献，最多1个；多张图片请以非PDF格式上传，谢谢。',
    figcheck_upload_error_pdf:'PDF文件格式有误,请换一个上传,谢谢',
    figcheck_upload_limit_pdf_page:'您的PDF文件已超过限定页数（{0}页）请酌情删减，谢谢。',
    figcheck_upload_limit_pdf_org:'机构用户PDF格式文献最多支持50个，谢谢。',
    figcheck_upload_limit_size_pdf:'单个pdf文献请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_size_image:'单个图片请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_size_total:'上传的图片或pdf文献总大小请不要超过{0}MB，谢谢。',
    figcheck_upload_limit_format:'拖入的不是图片(png/jpg/tif)、docx、pdf文献',
    figcheck_upload_limit_num:'检测超时，请尝试减少图片清晰度，减少上传图片数等',
    // figcheck_upload_limit_gt_count:'检测的图片过多,请缩减图片后再上传检测,检测到小图数量: {0} 张 检查最大小图限制: {1}张',
    figcheck_upload_limit_gt_count:'检测图片过多，或过于高清，请尝试删减图片或降低图片像素后重试，降低清晰度不影响检测灵敏度。',
    figcheck_upload_limit_not_gt_10:'一次检索，请不超过10个文件，谢谢。',
    figcheck_upload_limit_gt_3_100s:'温馨提示：你的账户累计{0}次查重时间大于{1}s，账户已自动锁定，如需继续使用，请联系我们，谢谢。',
    //结果部分
    figcheck_result_source_title:'可能相似的图片对: ',
    figcheck_result_source_title_no_result:'没有发现相似图片。',
    figcheck_result_ps_source_title:'可能存在ps痕迹的原图: ',
    figcheck_result_ps_source_title_no_result:'没有检测到有ps痕迹的图片。',
    figcheck_result_detail_title:'细节对比: ',
    figcheck_result_ps_detail_title:'ps痕迹预测: ',
    figcheck_result_detail_option_show_line:'显示线',
    figcheck_result_detail_option_show_line_intro:'红线连接两张图片之间相似的特征值，帮助指示特征值之间相似的方式，如复制、旋转、反转等。',
    figcheck_result_detail_option_remove_label:'去标注',
    figcheck_result_detail_option_remove_label_intro:'图片边缘的标注部分（如图示等）将不会纳入特征值比较，去除图片标注导致的假阳性干扰。',
    figcheck_result_detail_option_ps:'PS痕迹',
    figcheck_result_detail_option_ps_intro:'对文件中的WB图进行单独识别，类型包含针对条带的“复制，去除”2种图片操纵，经内部1000个样本测试，结果的真阳性率约为92.3%。',
    figcheck_result_single_ps_pred_prefix: '疑似',
    figcheck_result_single_ps_pred_text_add: '复制',
    figcheck_result_single_ps_pred_text_remove: '去除',
    // 提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图等；一般，红线越聚集，重复的可能性越大。请根据实际情况判断。
    figcheck_result_ps_no_wb:'结果中无wb图,无法使用ps查询。',
    figcheck_result_detail_prompt:'提示：人工智能算法大大提高了重复图片的识别效率，但上图红线所示重复区域不代表一定是有意义的重复，比如图标，merge的荧光图等；一般，红线越聚集，重复的可能性越大。请根据实际情况判断。',
    figcheck_result_ps_detail_prompt:'PS痕迹检测功能经内部1000个样本测试，结果的真阳性率约为92.3%，即以上高亮区域有92.3%可能是进行了右侧所示的图片操纵类型。但仍有7.7%的假阳性，请根据实际情况判断。',
    figcheck_result_down_pdf:'下载报告',
    figcheck_result_downing_pdf:'生成中...',
    //pdf报告
    figcheck_result_pdf_filename:'图片查重报告',
    figcheck_result_pdf_processing:'图片查重处理中,稍等片刻再下报告',
    //案例及友情链接
    figcheck_case_title:'图片重复案例',
    figcheck_links_title:'友情链接',
    figcheck_links_link_3:'国家卫健委',
    figcheck_links_link_4:'国务院',
    figcheck_links_link_5:'中华医学会',
    figcheck_links_link_6:'国自然基金委',
    //新增 9月18号

    //承诺书
    figcheck_book_link:'“学术诚信”倡议书',
    figcheck_book_title:'“学术诚信”倡议书',
    figcheck_book_content:'<p>\n' +
        '              致广大研究生和科研工作者：\n' +
        '            </p>\n' +
        '            <p>\n' +
        '              “文以行为本，在先诚其中”。诚实守信自古以来是中华民族的传统美德，文人以德行为修养根本，而以真诚为准则是自我修养的关键。作为肩负着中国梦的新时代科研工作者，在求学求真之路上更应正确认识诚信的本质和内涵，积极倡导诚实守信的道德观念。为积极响应国家和社会的号召，杜绝学术不端现象，现向全体研究生和科研工作者发出如下倡议：\n' +
        '            </p>\n' +
        '            <p>\n' +
        '              <span class="book-sub-title"> 一、不忘初心，共建诚信学风</span><br/>\n' +
        '              诚信，要求我们忠于事情的本来面貌，诚实面对事件的本质。当今社会，诚实守信是每一位公民的道德素养和立身之本。对于国家而言，实现中华民族伟大复兴，诚信友善更是社会发展的行为基础和价值共识。我们要高度重视并充分认识学术诚信的重要性和学术腐败的危害性。我们作为国家科研领域的一线人员，要从自身做起，用实际行动营造风清气正、积极向上、学风严谨的学术生态。\n' +
        '            </p>\n' +
        '            <p>\n' +
        '              <span class="book-sub-title">二、求实笃行，坚守学术道德</span><br/>\n' +
        '              科研在于创新，学术在于求真，诚实守信、严谨求实是保证学术道德的根本。我们要积极养成恪守规则的行为作风，树立求真务实、实事求是的学习态度，踏实治学。我们要培养自身学术诚信意识，塑造高尚学术品质，锤炼学术道德。我们要自觉维护和遵守国家有关法律、法规及学术规范，坚决反对投机取巧、弄虚作假、抄袭剽窃等违反学术规范与学术道德的行为，不买卖论文，不由他人代写或为他人代写论文，不侵占他人学术成果。我们要弘扬学术诚信，坚守诚信之德，守护学术净土。\n' +
        '            </p>\n' +
        '            <p>\n' +
        '              <span class="book-sub-title"> 三、崇教厚德，践行科研诚信</span><br/>\n' +
        '              作为新时代的科研者，积极倡导诚实守信的道德观念对砥砺品行、升华人格有重要意义。身处国家科研领域的一线，我们要在学术道德上率先垂范，成为良好学术建设的倡导者和践行者。我们要胸怀对学术的虔诚和敬畏，严以律己，切实将脚踏实地、严谨治学的优良学风落实到日常的学习与科研中去。我们要树立博学慎思、明辨笃行的治学态度，坚持理论联系实际，打造学术精品。我们要秉承学术良知，追求崇德向善、追求真理、守正创新的道德力量，坚守道德底线，践行科研诚信。\n' +
        '              同学们，同仁们，学术诚信对我们来说不仅仅是口号与标语，更是责任和担当。让我们携手并肩，共同呼吸学术诚信之风，聆听学术道德之声，胸怀理想，做一名无愧于时代重托的优秀科研人！\n' +
        '            </p>\n' +
        '            <p class="book-footer">\n' +
        '              Figcheck团队 <br/>\n' +
        '              2021年9月6日\n' +
        '            </p>',
    figcheck_book_cur_support:'当前已有{0}人支持',
    figcheck_book_btn_support:'支持',
    figcheck_book_btn_has_support:'已支持',
    figcheck_book_msg_support_succ:'支持成功',
    figcheck_book_msg_support_fail:'支持失败',
    //登录注册
    //公共
    figcheck_loginbox_input_email:'请输入邮箱',
    figcheck_loginbox_input_pass:'请输入密码',
    figcheck_loginbox_link_login_wechat:'微信登录',
    figcheck_loginbox_link_reg:'点击注册',
    figcheck_loginbox_link_find_pass:'找回密码',
    figcheck_loginbox_link_login:'点击登录',
    //登录
    figcheck_loginbox_login_title:'登录',
    figcheck_loginbox_login:'登录',
    //注册
    figcheck_loginbox_reg_title:'邮箱注册',
    figcheck_loginbox_send_code:'发送验证码',
    figcheck_loginbox_input_code:'请输入邮箱验证码',
    figcheck_loginbox_reg:'注册',
    //找回密码
    figcheck_loginbox_reset_title:'找回密码',
    figcheck_loginbox_input_new_pass:'请输入新密码',
    // figcheck_loginbox_confirm:'确认',
    //错误提示
    figcheck_loginbox_err_email:'输入的邮箱格式不正确,请重新输入',
    figcheck_loginbox_err_pass:'密码不合法,密码为6位以上数字字母组合',
    figcheck_loginbox_err_code:'邮箱验证码不合法,验证码为4位字母或数字',
    //消息提示
    figcheck_loginbox_msg_login_succ:'登录成功',
    figcheck_loginbox_msg_login_fail:'登录失败,获取用户信息失败,请重试',
    figcheck_loginbox_msg_send_limit:'发送邮件间隔限制,请稍等一会',
    figcheck_loginbox_msg_reg_succ:'新用户邮箱注册成功,可以通过邮箱和密码进行登录',
    figcheck_loginbox_msg_reset_succ:'密码重置成功,自动登录成功',
    figcheck_loginbox_msg_reset_succ_login_fail:'密码重置成功,自动登录失败,请尝试手动登录',
    figcheck_loginbox_msg_reset_fail:'密码重置失败,请重试',
    figcheck_loginbox_msg_send_email_fail:'发送邮件失败',
    figcheck_loginbox_msg_send_email_succ:'发送邮件成功',
    figcheck_loginbox_msg_code_err:'验证码错误,错误次数已达{0}次,还能重试次数{1}次',
    figcheck_loginbox_msg_code_err_no_num:'验证码错误',
    figcheck_loginbox_msg_login_gt_limit:'登录错误超过限制,请过会再试',
    figcheck_loginbox_msg_login_user_in_black_list:'你的账号已被锁定,暂时无法登录使用',
    figcheck_loginbox_msg_login_ip_in_black_list:'你的IP已被锁定,暂时无法登录使用',
    figcheck_loginbox_msg_user_pass_err:'账号或密码错误,还能重试{0}次',
    figcheck_loginbox_msg_user_not_exist:'用户可能不存在,请先注册',
    figcheck_loginbox_msg_user_has_exist:'用户已存在,请登陆',
    figcheck_loginbox_msg_reg_fail:'账号注册失败,请重新注册',
    figcheck_loginbox_msg_logout_succ:'注销成功',
    //邮箱验证码
    figcheck_email_titile:'邮箱验证码 by Figcheck',
    figcheck_email_content:'你好，请将以下验证码 0000 输入至注册页面输入框，完成注册，谢谢。',
    //头部菜单
    figcheck_menu_self_check:'学术自查',
    figcheck_menu_readme:'使用说明',
    figcheck_menu_pub_sub:'Pubpeer订阅',
    figcheck_menu_peer_review:'同行评议',
    figcheck_menu_api_access:'机构',
    //使用说明
    figcheck_readme_title:'Figcheck使用说明',
    figcheck_readme_sub_module_1_title:'基本介绍',
    figcheck_readme_sub_module_1_intro_1:'近年来，科研诚信受到学术界越来越重视， 其中，由于Pubpeer等平台的曝光，“图片重复使用”问题受到前所未有的关注。\n' +
        '                  Figcheck是同济大学创业谷项目，基于人工智能及神经网络算法可对预投稿图片或PDF文献中的图片实现一键自动化分割、重复识别、标注和报告打印。Figcheck致力于为科研人员、科研机构或期刊出版社等提供一款简便、免费的图片查重工具。',
    figcheck_readme_sub_module_2_title:'使用介绍',
    // 在<span class="red">{0}次</span>之内无需注册
    // figcheck_readme_sub_module_2_intro_1:'个人用户首次使用Figcheck。邮箱注册用户每天有{0}次查重机会。需要更多次数的机构（如出版社或者高校等），可以申请机构账户权限，无次数限制，可拥有批量查重权限。Figcheck同时提供API接入服务，机构账户权限申请流程详见（<a\n' +
    //     '                  href="https://www.figcheck.com/org_api" target="_blank" class="link">https://www.figcheck.com/org_api</a>）。',
    figcheck_readme_sub_module_2_intro_1:'个人用户使用Figcheck，微信登录后每天有{0}次查重机会。需要更多次数的机构（如出版社或者高校等），可以申请机构账户权限，无次数限制，可拥有批量查重权限。Figcheck同时提供API接入服务，机构账户权限申请流程详见（<a\n' +
        '                  href="https://www.figcheck.com/org_api" target="_blank" class="link">https://www.figcheck.com/org_api</a>）。',
    figcheck_readme_sub_module_2_intro_2:'登录Figcheck官网（<a href="https://www.figcheck.com/" target="_blank" class="link">https://www.figcheck.com/</a>）页面，“点击”或者拖拽上传文件，格式支持jpg, png, tif/tiff, pdf, zip，文件大小不大于30M。',
    figcheck_readme_sub_module_2_intro_3:'点击“开始检查”按钮启动。Figcheck只对上传文件或压缩文件范围内检测潜在的图片重复。Figcheck处理结果的时间，大约在10-20秒（按5张组合大图算）。这个时间和当时在线的请求人数也有关系。',
    figcheck_readme_sub_module_3_title:'结果解释',
    figcheck_readme_sub_module_3_label_1:'结果页面',
    figcheck_readme_sub_module_3_intro_1:'如上图，页面中间区域为潜在相似图片的原文位置（红框显示区），如示例中，一共有17个可能相似的图片对；页面右侧（细节对比）显示每一对图片的具体相似区域，默认用红线标示两张图片间相似的特征点以及重复变换的方式（剪切、翻转、旋转、缩放以及这些方式的组合）。如下图所示：',
    figcheck_readme_sub_module_3_label_2_1_1:'示例1',
    figcheck_readme_sub_module_3_label_2_1_2:'剪切',
    figcheck_readme_sub_module_3_label_2_2_1:'示例2',
    figcheck_readme_sub_module_3_label_2_2_2:'翻转',
    figcheck_readme_sub_module_3_label_2_3_1:'示例3',
    figcheck_readme_sub_module_3_label_2_3_2:'缩放',
    figcheck_readme_sub_module_3_intro_2:'Figcheck的阳性结果不代表一定是有意义的“图片操纵”，需要使用者根据实际情况判定，例如两种不同荧光颜色标记的细胞荧光图以及它们的merge图、一些显微镜的缩放图，还有一些标注的重复的现象，这些都是学术论文中正常的重复。',
    figcheck_readme_sub_module_3_intro_3:'理论上，两图中存在任何可信的相似点，都会被Figcheck标注，所以Figcheck会出现零星的假阳性结果，但这些假的重复可以被很容易分辨，因为这些重复点是没有意义的。如下图：',
    figcheck_readme_sub_module_3_label_3_1_1:'空白区域导致的假阳性',
    figcheck_readme_sub_module_3_label_3_1_2:'局部无意义的重复点',
    figcheck_readme_sub_module_3_intro_4:'几个小按钮可以帮助用户更好地解读结果，分别为“显示线”、“去标注”和“PS痕迹”。每个按钮的解释可以通过点击按钮旁边的‘？’查看。如下图所示：',
    figcheck_readme_sub_module_3_prompt_1:'显示线：',
    figcheck_readme_sub_module_3_text_1:'红线连接两张图片之间相似的特征点，也可以改为方框或不显示。',
    figcheck_readme_sub_module_3_prompt_2:'去标注：',
    figcheck_readme_sub_module_3_text_2:'图片边缘的标注部分将不会被纳入检查，这可以去除因此导致的假阳性。',
    figcheck_readme_sub_module_3_prompt_3:'PS痕迹：',
    figcheck_readme_sub_module_3_text_3:'对上传文件中的WB图进行ps痕迹检测（尚未开放）。',
    figcheck_readme_sub_module_4_video_1_desc:'个人用户使用视频介绍',
    figcheck_readme_footer_name:'Figcheck 团队',
    figcheck_readme_footer_contact:'联系我们：admin@figcheck.com',


    //pubpeer订阅
    figcheck_sub_tip:'为了方便使用订阅功能请先登录哟',
    figcheck_sub_back_home:'点击此logo能返回figcheck首页',
    figcheck_sub_header_tip:'Pubpeer是目前知名的学术不端曝光平台，这个工具可以帮助科研者实时监控目标文献是否被提及，并以邮件或短信形式提醒作者，以便作者及时做出回应。',
    figcheck_sub_block_add:'制定监控策略',
    figcheck_sub_input_doi:'请输入DOI',
    figcheck_sub_title:'标题',
    figcheck_sub_confirm:'确定',
    figcheck_sub_my:'我的监控策略',
    figcheck_sub_delete:'删除',
    figcheck_sub_no_tip:'还没有添加，可以添加最多20个~',
    figcheck_sub_term_gt_limit:'您添加的监控策略已超出限制，请发送邮件联系Figcheck工作人员，邮件地址: admin@figcheck.com',
    figcheck_sub_alarm_mode:'报警方式',
    figcheck_sub_email:'邮件',
    figcheck_sub_input_email:'这里输入邮箱地址',
    figcheck_sub_sms:'短信',
    figcheck_sub_input_phone:'这里输入电话号码',
    figcheck_sub_start:'开始追踪',
    figcheck_sub_stop:'关闭追踪',
    figcheck_sub_tracking:'pubpeer跟踪运行中... 系统将以短信/邮箱通知。',
    figcheck_sub_untrack:'尚未开启pubpeer追踪',
    figcheck_sub_alarm_his:'推送历史',
    figcheck_sub_alarm_his_no:'序号',
    figcheck_sub_alarm_his_title:'标题',
    figcheck_sub_alarm_his_doi:'DOI号',
    figcheck_sub_alarm_his_send_time:'发送时间',
    figcheck_sub_alarm_his_action:'操作',
    figcheck_sub_alarm_his_open:'点击查看',
    figcheck_sub_msg_hint:'提示',
    figcheck_sub_msg_delete_article:'确认删除此监控策略吗？',
    figcheck_sub_msg_confirm:'确认',
    figcheck_sub_msg_cancel:'取消',
    figcheck_sub_msg_delete_msg:'确认删除此推送记录吗？',
    figcheck_sub_msg_no_input:'您还未填写邮箱哦',
    figcheck_sub_msg_enter:'马上填写',
    figcheck_sub_msg_reenter:'重新输入',
    figcheck_sub_msg_err_email:'输入的邮箱格式不正确,请重新输入',
    figcheck_sub_msg_err_phone:'输入的手机号不正确,请重新输入',
    figcheck_sub_msg_err_doi:'doi必填',
    figcheck_sub_msg_err_article_exist:'此监控策略已存在',
    figcheck_sub_msg_err_out_limit:'您添加的监控策略已超出限制',
    figcheck_sub_msg_err_unavai_article_id:'无文章id',
    figcheck_sub_msg_err_no_id:'无id',
    figcheck_sub_msg_err_alarm_illegel_id:'报警历史id非法',
    figcheck_sub_msg_err_no_his_id:'无指定id的历史记录',
    figcheck_sub_msg_login_first:'您还未登录呢',
    figcheck_sub_msg_added:'添加成功',
    figcheck_sub_msg_removed:'删除成功',
    figcheck_sub_msg_delete_fail:'删除失败',
    figcheck_sub_msg_opened:'开启成功',
    figcheck_sub_msg_closed:'关闭成功',
    figcheck_sub_msg_open_fail:'开启失败',
    figcheck_sub_msg_close_fail:'关闭失败',
    //机构api
    figcheck_org_api_box_1_title:'机构开放',
    figcheck_org_api_box_1_text:'图片重复使用等学术不端行为是学术界共同的敌人。Figcheck平台基于人工智能算法，致力于为学术机构及学术期刊等非盈利性组织提供免费的图片查重服务，帮助高校、机构及学术期刊等科学相关组织及时发现并纠正图片不当使用，维护科研组织的声誉以及科学研究的真实性。学术团体、课题组、高校、期刊出版社等机构可以通过以下3种方式获得更高权限：',
    figcheck_org_api_box_1_dot_1:'不限制的机构账户(不限制日查重数）',
    figcheck_org_api_box_1_dot_2:'批量查重权限',
    figcheck_org_api_box_1_dot_3:'通过API接口接入功能',
    figcheck_org_api_box_1_dot_video:'视频演示',
    figcheck_org_api_box_2_title:'应用场景:',
    figcheck_org_api_box_2_dot_1:'出版社对稿件进行批量图片审查',
    figcheck_org_api_box_2_dot_2:'学术或医疗机构回查过往已发表文章',
    figcheck_org_api_box_2_dot_3:'学术机构或个人投稿前自查',
    figcheck_org_api_box_3_title:'适合对象：',
    figcheck_org_api_box_3_dot_1:'期刊出版社',
    figcheck_org_api_box_3_dot_2:'高校、研究所',
    figcheck_org_api_box_3_dot_3:'课题组',
    // figcheck_org_api_box_3_dot_4:'对学术圈<b>免费</b>',
    figcheck_org_api_apply_step:'申请流程',
    figcheck_org_api_apply_type_1_title:'申请机构账号',
    figcheck_org_api_apply_type_1_text:'适合没有自有网站和投稿系统的机构，如研究所或课题组教授等，授予机构权限，获得批量图片查重等权限。 ',
    figcheck_org_api_apply_type_1_step_1_title:'联系我们',
    figcheck_org_api_apply_type_1_step_1_text:'通过网站下方提供的方式与我们取得联系',
    figcheck_org_api_apply_type_1_step_2_title:'提交资料',
    figcheck_org_api_apply_type_1_step_2_text:'能够证明该机构的材料和联系人',
    figcheck_org_api_apply_type_1_step_3_title:'签署协议',
    figcheck_org_api_apply_type_1_step_3_text:'包括保密协议',
    figcheck_org_api_apply_type_1_step_4_title:'分配账号',
    figcheck_org_api_apply_type_1_step_4_text:'原个人账户获得机构权限',
    figcheck_org_api_apply_type_2_title:'通过API接口接入功能',
    figcheck_org_api_apply_type_2_text:'适合有网站和投稿系统的机构，如出版社等，通过接口直接整合figcheck功能。',
    figcheck_org_api_apply_type_2_step_1_title:'联系我们',
    figcheck_org_api_apply_type_2_step_1_text:'通过网站下方提供的方式与我们取得联系',
    figcheck_org_api_apply_type_2_step_2_title:'提交资料',
    figcheck_org_api_apply_type_2_step_2_text:'能够证明该机构的材料和联系人',
    figcheck_org_api_apply_type_2_step_3_title:'签署协议',
    figcheck_org_api_apply_type_2_step_3_text:'包括软件服务合同，保密协议等',
    figcheck_org_api_apply_type_2_step_4_title:'绑定场景',
    figcheck_org_api_apply_type_2_step_4_text:'技术人员根据机构场景，协助机构建立专项对接小组',
    figcheck_org_api_contact:'联系我们',
    figcheck_org_api_email:'邮箱',
    figcheck_org_api_phone:'电话',
    //同行评议
    figcheck_peer_review_add_report:'我要曝光',
    figcheck_peer_review_comment:'评论',
    figcheck_peer_review_hide:'收缩',
    figcheck_peer_review_more:'展开',
    figcheck_peer_review_floor:'楼',
    figcheck_peer_review_input_hint:'写下你的想法',
    figcheck_peer_review_preview:'预览',
    figcheck_peer_review_reply:'回复',
    figcheck_peer_review_load_more:'点击加载更多',
    figcheck_peer_review_no_more:'没有更多了',
    figcheck_peer_review_article_info:'填写曝光文献信息',
    figcheck_peer_review_title:'标题',
    figcheck_peer_review_authors:'作者',
    figcheck_peer_review_journal:'期刊名',
    figcheck_peer_review_doi:'DOI号',
    figcheck_peer_review_tip:'填入doi号能自动补全其余信',
    figcheck_peer_review_year:'年份',
    figcheck_peer_review_choose_year:'选择年',
    figcheck_peer_review_contents:'内容',
    figcheck_peer_review_loading:'加载中...',
    figcheck_peer_review_reply_floor:'回复{0}楼:',
    figcheck_peer_review_reply_floor_reg: /回复(\d+)楼:/,
    figcheck_peer_review_commented:'评论成功',
    figcheck_peer_review_released:'发布成功',
    figcheck_peer_review_login_first:'你还未登录，请登录后再试',
    figcheck_peer_review_content_is_null:'爆料内容为空，请填上爆料内容',
    figcheck_peer_review_doi_is_null:'doi为空,doi为必填',
    figcheck_peer_review_add_fail:'发布失败,请稍后再试',
    figcheck_peer_review_comments_is_null:'评论内容不能为空',
    figcheck_peer_review_no_right:'没权限',

    // 10-4
    // 期刊直通车
    //公共
    document_title_subway:'期刊直通车',
    //头部
    subway_menu_coop_journal: '合作期刊',
    subway_menu_editor_team: '编辑团队',
    subway_menu_privacy_policy: '隐私政策',
    subway_user_login: '账号登录',
    subway_lang: '语言',
    subway_lang_zh: '中文',
    subway_lang_en: 'english',
    subway_search_hint: 'Title/keywords 搜索期刊',
}
