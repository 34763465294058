import axios from 'axios'
import sciStore from "../../store/modules/sciStore";
import utils from "./utils";

import loginApi from "../loginApi";
import config from "./config";
import commonUtils from "../../../common/commonUtils";
import commonConsts from "../../../common/commonConsts";

let edUtils=commonUtils;

let ENCRYPT_RESTRESULT=config.ENCRYPT_RESTRESULT;
// let TOKEN_COOKIE_NAME='tk'




function getJsonContentTypeHead(){
  return {
    'Content-Type':'application/json'
  }
}

let notLoginTipText=commonConsts.RESEARCH_NOT_LOGIN

let noVipText=commonConsts.RESEARCH_NOT_VIP_COUNT;

function ajaxInit() {
  window.globalMethods.openLoad()
}

function ajaxAfter() {
  window.globalMethods.closeLoad()
}

function processCommonCode(code) {

  switch (code) {
    case 600:

      window.globalMethods.swichTip(true,'ee',commonConsts.BLACK_USER,
        ()=>{})

      return  false;
    case 403:

      window.globalMethods.swichTip(true,'e',commonConsts.NOT_VIP_COUNT,null,
        ()=>{
          window.open(commonConsts.RESEARCH_PAY_URL,"_blank");
        })

      return  false;
  }

  return true;
}

function baseHttpAjax(url,method,data,headers,success,error,initTip,careUserInfo,needError) {
  if(!utils.varNotNull(careUserInfo)){
    careUserInfo=false;
  }
  if(!utils.varNotNull(initTip)){
    initTip=true;
  }
  if(initTip){
    ajaxInit()
  }
  if (!method){
    method='GET'
  }
  if (!data){
    data={}
  }
  if (!headers){
    headers={}
  }
  let transformRequest=[]
  if( ('Content-Type' in headers)   && (headers['Content-Type']=='application/json')){
    if(!commonUtils.isString(data)){
      data=JSON.stringify(data)
    }
  }else{
    transformRequest.push(function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    })
  }
  let param={}
  if(method=='GET'){
    param=data
  }
  if(sciStore.state.userInfo==null && careUserInfo){
    setTimeout(()=>{
      window.globalMethods.swichTip(true,'e',notLoginTipText,()=>{

      },()=>{
        window.globalMethods.gotoPage('/')
      })
    },200)
    if(initTip){
      ajaxAfter()
    }
    if(needError){
      if(error){
        error()
      }
    }
    return
  }

  try {
    let tttttk=commonUtils.getLocalStorage('tk')
    if(commonUtils.varNotNull(tttttk)){
      headers['tk']=tttttk
    }

  }catch (e) {

  }

  axios({
    url,
    method,
    params:param,
    data,
    headers,
    transformRequest: transformRequest
  })
    .then((res) => {
      //res为ajax封装的data   res.data为后端返回的结果
      let end=false;
      let commonCodeRes=true;
      if(res && res.data){
        if(ENCRYPT_RESTRESULT){
          try {
            res.data=edUtils.restResultDecode(res.data);
            // eslint-disable-next-line no-empty
          }catch (e) {

          }
        }
        let d=res.data;
        if(d.code){
          if(d.code==10000){
            console.log('需要登录',d)
            sciStore.state.userInfo=null;
            if(window.globalMethods['swichTip']&&window.globalMethods['gotoPage'] && careUserInfo){
              console.log('后端返回了需要登录')
              window.globalMethods.swichTip(true,'e',notLoginTipText,()=>{
                window.globalMethods.gotoPage('/')
              })
              end=true;
            }
          }else{
            if(sciStore.state.userInfo==null&&careUserInfo){
              console.log('前端检测到没有用户信息',careUserInfo)
              // eslint-disable-next-line no-unused-vars
              loginApi.getUsername((res) => {},error=>{
                window.globalMethods.swichTip(true,'e',notLoginTipText,()=>{
                  window.globalMethods.gotoPage('/')
                })
              },false)
              end=true;
            }
          }
          commonCodeRes=processCommonCode(d.code)
        }
      }
      if(initTip){
        ajaxAfter()
      }
      if(end)
        return
      if (success!=null && commonCodeRes){
        if (res&&res.status){
          success(res)
        }else{
          error(res)
        }
      }
      // console.log('ajax '+method+' success: ',res)
    }).catch((res) => {
      let code=null
        if (res.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          code=error.response.status
        }
    if (error!=null){
      if(code==503||code=='503'){
        window.globalMethods.swichTip(true,'ee','请求超时，请重新再试一次~',()=>{})
      }else{
        error(res)
      }
    }
    console.log('ajax '+method+' error: ',res)
    if(initTip){
      ajaxAfter()
    }
  })
}

function GET(url,data,success,error,head,initTip,careUserInfo) {


  baseHttpAjax(url,'GET',data,head,(res) => {


    if(res.code==200||res.status){
      if (success!=null){
        success(res)
      }
    }else{
      if (error!=null){
        error(res)
      }
    }

  },(res) => {
    if (error!=null){
      error(res)
    }
  },initTip,careUserInfo)

}

function POST(url,data,success,error,head,initTip,careUserInfo,needError) {
  baseHttpAjax(url,'POST',data,head,(res) => {
      if(res.status){
        if (success!=null){
          success(res)
        }
      }else{
        if (error!=null){
          error(res)
        }
      }

    },(res) => {
      if (error!=null){
        error(res)
      }
    }
    ,initTip,careUserInfo,needError)


}

function PUT(url,data,success,error,head,initTip,careUserInfo) {

  baseHttpAjax(url,'PUT',data,head,(res) => {

    if(res.code==200){
      if (success!=null){
        success(res.data)
      }
    }else{
      if (error!=null){
        error(res)
      }
    }

  },(res) => {
    if (error!=null){
      error(res)
    }
  },initTip,careUserInfo)


}

function DELETE(url,data,success,error,head,initTip,careUserInfo) {

  baseHttpAjax(url,'DELETE',data,head,(res) => {

    if(res.code==200){
      if (success!=null){
        success(res.data)
      }
    }else{
      if (error!=null){
        error(res)
      }
    }

  },(res) => {
    if (error!=null){
      error(res)
    }
  },initTip,careUserInfo)


}


function get(url,data,head,initTip,careUserInfo) {

return new Promise((resolve, reject) => {
  baseHttpAjax(url,'GET',data,head,(res) => {
    if(res.code==200){
        resolve(res.data)
    }else{
        reject(res)
    }

  },(res) => {
      reject(res)
  },initTip,careUserInfo)
})
}
function postJSON(url,data,head,initTip,careUserInfo) {

  if (commonUtils.varIsNull(head)){
    head=getJsonContentTypeHead();
  }



  return new Promise((resolve, reject) => {
    baseHttpAjax(url,'POST',data,head,(res) => {


      let msg=commonUtils.getValFromObj(res,"data.msg");
      let type=commonUtils.getValFromObj(res,"data.type");
        if(type=='error' && msg!=null){
          switch (msg) {
            case '计算频率限制':
              msg=commonConsts.COMMON_SPEED_OVER_ERROR
              window.globalMethods.swichTip(true,'e',msg,()=>{

              })
              break
            case '计算次数已满':
              msg=commonConsts.COMMON_STATISTICS_NOT_CALC_COUNT
              window.globalMethods.swichTip(true,'e',msg,null,()=>{
                window.open(commonConsts.RESEARCH_PAY_URL)
              },null,'马上升级')
              break
            case '超限':
              msg='当前我的样本库个数超过限制，请升级或删除现存样本'
              window.globalMethods.swichTip(true,'e',msg,null,()=>{
                window.open(commonConsts.RESEARCH_PAY_URL)
              },null,'马上升级')
              break
            case '保存失败':
              msg='保存失败'+","+commonConsts.COMMON_RES_ERROR
              window.globalMethods.swichTip(true,'e',msg,()=>{

              })
              break
            case "出错":
              msg='操作失败'+","+commonConsts.COMMON_RES_ERROR
              window.globalMethods.swichTip(true,'e',msg,()=>{

              })
              break
            case "geo超限":
              msg='项目个数超过限制，请删除无用的项目再保存'
              window.globalMethods.swichTip(true,'e',msg,()=>{

              })
              break

            default :
              window.globalMethods.swichTip(true,'e',msg,()=>{

              })
              break;
          }


        }else{
          if(res.code==200){
            resolve(res.data)
          }else{
            reject(res)
          }
        }


      },(res) => {
          reject(res)
      }
      ,initTip,careUserInfo)
  }
)

}


export default {
  GET,PUT,DELETE,POST,noVipText,getJsonContentTypeHead,postJSON,get
}
